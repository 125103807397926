import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Xysyboi Golf Course - Where Golf Dreams Come True
			</title>
			<meta name={"description"} content={"In the enchanting realm of Xysyboi Golf Course, we invite you to embark on a golfing odyssey unlike any other. This isn't just a golf course; it's a story waiting to be written."} />
			<meta property={"og:title"} content={"Home | Xysyboi Golf Course - Where Golf Dreams Come True"} />
			<meta property={"og:description"} content={"In the enchanting realm of Xysyboi Golf Course, we invite you to embark on a golfing odyssey unlike any other. This isn't just a golf course; it's a story waiting to be written."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span color="--green">
						Xysyboi
					</Span>
					{" "}Golf Course
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					In the enchanting realm of Xysyboi Golf Course, we invite you to embark on a golfing odyssey unlike any other. This isn't just a golf course; it's a story waiting to be written.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contacts"
						padding="12px 54px 12px 54px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-green"
						hover-background="#1e6856"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Find us
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_3.webp?v=2023-10-24T11:08:46.157Z) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11:08:46.199Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_2.webp?v=2023-10-24T11%3A08%3A46.199Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11:08:46.155Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/court_2.webp?v=2023-10-24T11%3A08%3A46.155Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11:08:46.082Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11:08:46.204Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_5.webp?v=2023-10-24T11%3A08%3A46.204Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Our Services
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Imagine immaculate greens that seem to whisper promises of a remarkable game. These greens, meticulously nurtured, promise an exquisite canvas upon which your golfing journey unfolds. With each swing, you'll be captivated by the magic of a smooth and consistent playing surface, ensuring that your game is nothing less than extraordinary.
					<br />
					<br />
					As you navigate the course, you'll encounter our Signature Holes, each a chapter in the tale of your golf adventure. These holes, with their unique twists and challenges, breathe life into the sport. Whether you're a newcomer or a seasoned pro, each hole beckons you to test your skills, to unlock your potential, and to create unforgettable moments on this golfing canvas.
					<br />
					<br />
					Upon completing your golfing masterpiece, follow the path to our Classic Clubhouse, a sanctuary of camaraderie and relaxation. Here, in this welcoming haven, you can relive your triumphs, share stories with fellow golfers, and savor refreshments and light bites. It's a place where the echoes of your best shots harmonize with the satisfaction of conquering our timeless course.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11:08:46.117Z"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/play_3.webp?v=2023-10-24T11%3A08%3A46.117Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11:08:46.082Z"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						srcSet="https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/653790b5cd81ee001f7e2823/images/ball_2.webp?v=2023-10-24T11%3A08%3A46.082Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					But Xysyboi Golf Course is more than just a golfing destination; it's an epic journey. It's a voyage of self-discovery, a sojourn to find serenity away from the chaos of everyday life, and a chance to immerse yourself in the purest form of golf.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Join Us Today
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Reserve your tee time or explore our training programs now. At Xysyboi Golf Course, every swing tells a unique story, and your golfing odyssey begins in our serene oasis designed to make your golf dreams come true.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
				background="--color-green"
			>
				Contact Us
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});